@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/dm/DMSans-MediumItalic.woff2') format('woff2'),
  url('../assets/fonts/dm/DMSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/dm/DMSans-Italic.woff2') format('woff2'),
  url('../assets/fonts/dm/DMSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/dm/DMSans-BoldItalic.woff2') format('woff2'),
  url('../assets/fonts/dm/DMSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/dm/DMSans-Bold.woff2') format('woff2'),
  url('../assets/fonts/dm/DMSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/dm/DMSans-Regular.woff2') format('woff2'),
  url('../assets/fonts/dm/DMSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('../assets/fonts/dm/DMSans-Medium.woff2') format('woff2'),
  url('../assets/fonts/dm/DMSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}


%necessities-button {
  @apply active:scale-95 scale-100 transition duration-150 hover:brightness-90 disabled:cursor-no-drop disabled:bg-brand-gray-200 disabled:text-brand-gray-400 select-none;
}

.primary-button {
  @extend %necessities-button;
	@apply flex bg-brand-dark rounded-full gap-2 items-center justify-center w-full px-6 py-3 hover:bg-brand-dark-gray text-white font-medium;
}

.secondary-button {
  @extend %necessities-button;
	@apply flex enabled:bg-brand-lilac-600 rounded-full gap-2 items-center justify-center w-full px-6 py-3 hover:enabled:bg-[#2f3175] enabled:text-white disabled:bg-brand-gray-200 font-medium;
}

.tertiary-button {
  @extend %necessities-button;
  @apply flex items-center justify-center gap-2 bg-white p-4 shadow rounded-full hover:bg-brand-gray-50 aspect-square;
}

.option-button {
  @extend %necessities-button;
	@apply flex items-center justify-center gap-2 bg-brand-gray-200 py-1.5 px-4 rounded-full font-medium h-fit disabled:opacity-25;
}

.delete-button {
  @extend %necessities-button;
	@apply flex bg-[#FFE8E8] rounded-full gap-2 items-center justify-center w-full px-6 py-3 hover:filter text-brand-error-800;
}

.svg-fill-primary {
	filter: brightness(0) saturate(100%) invert(3%) sepia(5%) saturate(2468%) hue-rotate(169deg) brightness(93%) contrast(96%);
}

.svg-fill-lilac-400 {
	filter: brightness(0) saturate(100%) invert(22%) sepia(15%) saturate(6975%) hue-rotate(222deg) brightness(98%) contrast(85%);
}

.svg-fill-white {
	filter: invert(100%) sepia(59%) saturate(0%) hue-rotate(146deg) brightness(116%) contrast(100%);
}

.svg-fill-gray-400 {
  filter: brightness(0) saturate(100%) invert(43%) sepia(7%) saturate(700%) hue-rotate(178deg) brightness(98%) contrast(82%);
}

.svg-fill-gray-200 {
  filter: invert(94%) sepia(15%) saturate(47%) hue-rotate(182deg) brightness(100%) contrast(88%);
}

.svg-fill-error-800 {
	filter: brightness(0) saturate(100%) invert(13%) sepia(65%) saturate(2577%) hue-rotate(342deg) brightness(103%) contrast(95%);
}

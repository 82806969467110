@tailwind base;
@tailwind components;
@tailwind utilities;

@import "styles/font";
@import "styles/text";
@import "styles/components";
@import "styles/button";
@import "styles/img-fill";
@import "styles/ionic-custom";
@import "styles/icons";

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

:host {
  width: 100vw !important;
  height: 100vh !important;
}

* {
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //outline: red dashed 1px;
}

@layer base {
  html {
    font-family: var(--color-custom-font), "DM Sans", serif !important;
  }
}

body {
  width: 100vw !important;
  height: 100vh !important;
  margin: 0;
  padding: 0;
}


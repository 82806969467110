p {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

label {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
}

h1 {
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 40px;
  @apply text-2xl md:text-3xl;
}

h2 {
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 32px;
}


h3 {
	font-style: normal;
	font-weight: 500;
	line-height: 28px;
}

h4 {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

p {
  font-weight: 500;
}

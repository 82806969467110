%icon_base {
  -webkit-mask-size: contain;
  mask-size: contain;
  mask-repeat: no-repeat;
  -webkit-mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-position: center;
  text-rendering: auto;
  width: 16px;
  height: 16px;
  min-height: 16px;
  min-width: 16px;
  display: block;
}

.icon-calendar {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/calendar.svg');
  mask-image: url('../assets/icon/calendar.svg');
}


.icon-sms {
  @extend %icon_base;
  -webkit-mask-image: url('../assets/icon/sms.svg');
  mask-image: url('../assets/icon/sms.svg');
}

ion-modal {
	--border-radius: 16px !important;
	--width: auto;
	--height: auto;
  --ion-background-color: transparent;
  --padding-bottom: 30px;
}

.modal-full-size {
  --height: 100%;
  --width: 100%;
  --border-radius: 0;
  border-radius: 0;
  --box-shadow: none;
  padding-bottom: 16px;
  padding-top: 16px;
}

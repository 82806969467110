//.input {
//	@apply bg-white text-gray-900 rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
//}

.shadow-input {
	@apply p-3 border border-brand-gray-200 focus:outline-none;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
	border-radius: 4px;
}

.box-input {
	@apply inline-flex w-full justify-between rounded-md border border-brand-gray-200 bg-white p-4 text-sm font-medium shadow-sm hover:bg-gray-50 focus:outline-none;
}

th {
	@apply font-semibold text-brand-gray-400 px-2 py-1 text-left;
}

td {
	@apply h-fit text-gray-900 font-light px-2 py-4 whitespace-nowrap;
}

table tbody tr {
	@apply rounded-lg overflow-hidden hover:bg-white hover:shadow cursor-pointer py-4 transition-all duration-150;
}

table tbody td {
	display: table-cell
}

td:first-child,
th:first-child {
	@apply rounded-bl-lg rounded-tl-lg;
}

td:last-child,
th:last-child {
	@apply rounded-br-lg rounded-tr-lg;
}

.settings-content {
  @apply flex lg:flex-row flex-col lg:gap-20 gap-4 w-full p-6;
}

.settings-content-info {
  @apply flex flex-col gap-2 min-w-[250px] lg:w-[250px] lg:max-w-[250px] w-full;
}
